import { is404Error, is5xxError } from "helpers/api_helper"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_RESET_ERRORS,
} from "./actionTypes"

const initialState = {
  clientError: null,
  serverError: null,
  errorStatus: 0,
  loading: false,
}

const setErrorType = ({ error, errorStatus }) => {
  if (is5xxError(errorStatus) || is404Error(errorStatus)) return { serverError: error };
  return { clientError: error };
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        ...resetErrorState(),
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, ...setErrorType(action.payload), errorStatus: action.payload.errorStatus, loading: false }
      break
    case LOGIN_RESET_ERRORS:
      state ={ ...state, ...resetErrorState()}
    default:
      state = { ...state }
      break
  }
  return state
}

const resetErrorState = () => ({
  clientError: null,
  serverError: null,
  errorStatus: 0,
});

export default login
