import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const LivePracticeTimeChart = () => {
    const options = {
        chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
        colors: ["#556ee6", "#34c38f"],
        dataLabels: { enabled: !0 },
        stroke: { width: [3, 3], curve: "straight" },
        grid: {
          row: { colors: ["transparent", "transparent"], opacity: 0.2 },
          borderColor: "#f1f1f1",
        },
        markers: { style: "inverted", size: 6 },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: { text: "Month" },
        },
        yaxis: { title: { text: "Time spent in hours" }, min: 5, max: 40 },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: !0,
          offsetY: -25,
          offsetX: -5,
        },
        responsive: [
          {
            breakpoint: 600,
            options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
          },
        ],
    };

    const series = [
        { name: "Time", data: [14, 11, 16, 12, 17, 13, 12] },
    ];

    return (
        <>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="380"
                className="apex-charts"
            />
        </>
    );
};

export default LivePracticeTimeChart;