import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import styles from './Toggle.module.scss';

const Toggle = ({ id, properties, selectedProperty, setSelected }) => {
    return (
        <div className={styles.toggleWrapper}>
            { properties.map((property, index) => (
                <Button 
                    id={`${id}-${property.replace(/ /g,"-")}`}
                    key={index} 
                    className={selectedProperty === property ? styles.selected : null}
                    onClick={() => setSelected(property)}
                >
                    { property }
                </Button>)
            )}
        </div>
    )
};

Toggle.propTypes = {
    id: PropTypes.string,
    properties: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedProperty: PropTypes.string.isRequired,
    setSelected: PropTypes.func.isRequired,
};

export default Toggle;

