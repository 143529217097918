import React from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";

import { Col, Container, Row } from "reactstrap";

import logodark from "../../assets/images/logos/logo_website.png";

import CarouselPage from "pages/AuthenticationInner/CarouselPage";

const LoginContainer = ({ children }) => {
  return (
    <div className="account-pages">
      <MetaTags>
        <title>Login | Chatterbox</title>
      </MetaTags>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />
          <Col xl={4} sm={{ size: 6 }} className="mx-auto">
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 w-100 logo-wrapper">
                    <img
                      src={logodark}
                      alt=""
                      height="25"
                      className="auth-logo-dark"
                    />
                  </div>
                  {children}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

LoginContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LoginContainer;
