import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import styles from './AssignLicenceModal.module.scss';
import Form from 'pages/Ui/Form/Form';
import RegularFormField from 'pages/Ui/Form/RegularFormField';
import { validateEmail } from 'helpers/Validators';
import FormDropdown from 'components/Common/Dropdown/Dropdown';
import { connect } from 'react-redux';
import { assignNewLicence, getAvailableOrders, resetManageLicences } from 'store/manageLicences/actions';
import Button from 'pages/Ui/Button/Button';

const AssignLicenceModal = ({
    assignNewLicenceAction,
    availableOrders,
    getAvailableOrdersAction,
    handleCloseModal,
    isLoadingAvailableOrders,
    isLoadingAssigned,
    isSuccess,
    isModalOpen,
    resetManageLicencesAction 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [orderType, setOrderType] = useState('');

    useEffect(() => {
        getAvailableOrdersAction();

        return () => resetManageLicencesAction();
    }, []);

    useEffect(() => {
        if (isSuccess) setIsOpen(false);
    }, [isSuccess])

    const handleModalToggle = () => {
        handleCloseModal();
        setIsOpen(prevState => !prevState);
    }

    useEffect(() => {
        setIsOpen(isModalOpen);
    }, [isModalOpen])

    const handleEmailInput = (evt) => {
        setNewEmail(evt.target.value);
    }

    const handleOrderTemplateSelection = (order) => {
        setOrderType(order);
    }

    const handleAssignNewLicence = () => {
        const payload = { newEmail, orderType };
        assignNewLicenceAction(payload);
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={handleModalToggle}
            centered={true}
        >
            <div className={styles['modal__title']}>
                <h5>Assign a licence</h5>
                <button
                    type="button"
                    onClick={handleModalToggle}
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className={styles['modal__content']}>
                <RegularFormField
                    id="assign-new-licence-modal-email"
                    label="New student email"
                    type="email"
                    onChange={handleEmailInput}
                    validation={{ validator: validateEmail, message: 'Invalid e-mail' }}
                    value={newEmail}
                />
                <FormDropdown
                    id='assign-new-licence-modal-order'
                    key={Math.random()}
                    label='Order template'
                    caret
                    clearSelectedValue={() => setOrderType('')}
                    options={availableOrders.map((order) => (
                        <button className={styles['dropdown__option']} key={Math.random()} onClick={() => handleOrderTemplateSelection(order)} >
                            {order}
                        </button>
                    ))}
                    isLoading={isLoadingAvailableOrders}
                    placeholder="Select an order template"
                    value={orderType}
                />
                <div className={styles['modal__action-button']}>
                    <Button onClick={handleAssignNewLicence} isLoading={isLoadingAssigned}>Assign</Button>
                </div>
            </div>
        </Modal>
    );
};

AssignLicenceModal.propTypes = {
    assignNewLicenceAction: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    getAvailableOrdersAction: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    availableOrders: PropTypes.array.isRequired,
    isLoadingAvailableOrders: PropTypes.bool.isRequired,
    isLoadingAssigned: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    resetManageLicencesAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoadingAvailableOrders: state.ManageLicences.AvailableOrders.isLoading,
    availableOrders: state.ManageLicences.AvailableOrders.availableOrders,
    isLoadingAssigned: state.ManageLicences.Assigned.isLoading,
    isSuccess: state.ManageLicences.Assigned.isSuccess,
});

export default connect(mapStateToProps, {
    getAvailableOrdersAction: getAvailableOrders,
    assignNewLicenceAction: assignNewLicence,
    resetManageLicencesAction: resetManageLicences,
})(AssignLicenceModal);