import React from 'react';
import PropTypes from 'prop-types';

import styles from './IconTooltip.module.scss';

const IconTooltip = ({
    icon,
    onClick,
    text,
    position,
    moreThanOneLine,
}) => {

    const handleClick = () => {
        if (!onClick) return;

        onClick(text);
    }

    return (
        <span className={styles.tooltipWrapper} onClick={handleClick}>
            <span >{ icon }</span>
            <div className={[styles.tooltipContent, styles[position]].join(' ')}>
                <div className={[styles.tooltipText, moreThanOneLine && styles["more-than-one-line"]].join(' ')}>{ text }</div>
            </div>
        </span>
    )
};

IconTooltip.propTypes = {
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func,
    text: PropTypes.any.isRequired,
    position: PropTypes.string,
    moreThanOneLine: PropTypes.bool,
};

IconTooltip.defaultProps = {
    position: 'right',
    moreThanOneLine: false,
}

export default IconTooltip;