import ChartCard from 'components/Common/Card/CardChart';
import React from 'react';
import EarlyEngagementChart from './EarlyEngagementChart';

const EarlyEngagement = () => {
    return (
        <ChartCard 
            cardTitle="Early engagement (1st month)"
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            <EarlyEngagementChart />
        </ChartCard>
    )
};

export default EarlyEngagement;