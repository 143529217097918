import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";

import styles from './InformationCard.module.scss';
import IconTooltip from '../Tooltip/IconTooltip';
// import TrendIcon from '../DynamicIcons/TrendIcon';

const InformationCard = ({
    cardTitle,
    emotion,
    tooltipIcon,
    tooltipText,
    value,
    percentage,
}) => {
    const [trend, setTrend] = useState('up');

    useEffect(() => {
        if (percentage > 0) setTrend('up');
        if (percentage < 0) setTrend('down');
    }, [percentage])
    return (
        <Card className={styles.cardWrapper}>
            <CardBody className={[styles.cardBody, styles[emotion]].join(' ')}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={styles.cardHeader}>
                        <span className={styles.cardTitle}>{ cardTitle }</span>
                        {tooltipIcon && 
                            <IconTooltip
                                icon={ tooltipIcon }
                                text={ tooltipText }
                            />
                        }
                    </div>
                    <div className={styles.cardNumbers}>
                        <span className={styles.cardValue}>{ value }</span>
                        {/* <div className={styles.cardGrowth}>
                            <TrendIcon trend={trend} value={percentage || 0}/>
                        </div> */}
                    </div>
                </div>
            </CardBody>
        </Card>
    )
};

InformationCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    emotion: PropTypes.string.isRequired,
    tooltipIcon: PropTypes.element,
    tooltipText: PropTypes.string,
    value: PropTypes.number,
    percentage: PropTypes.number,
}

export default InformationCard;