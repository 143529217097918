import React from "react";
import { Redirect } from "react-router-dom";

// User profile
import UserProfile from "../pages/UserProfile";

// Authentication related pages
import LoginComponent from "../pages/Authentication/Login";
import ForgotPasswordComponent from "../pages/Authentication/ForgotPassword";
import SetPasswordComponent from "../pages/Authentication/SetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Individuals from "../pages/Individuals/index";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesMaintenance from "pages/Utility/pages-maintenance";
import PagesComingsoon from "pages/Utility/pages-comingsoon";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/individuals", component: Individuals },

  //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/individuals" /> },
];

const publicRoutes = [
  { path: "/login", component: LoginComponent },
  { path: "/forgot-password", component: ForgotPasswordComponent },
  { path: "/set-password", component: SetPasswordComponent },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

const notFoundRoutes = [{ path: "*", component: Pages404 }];

export { authProtectedRoutes, publicRoutes, notFoundRoutes };
