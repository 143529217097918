import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";

// actions
import { loginUser, resetLoginErrors } from "../../store/actions";

import Form from "../Ui/Form/Form";
import LoginContainer from "./Container";
import RegularFormField from "pages/Ui/Form/RegularFormField";
import PasswordFormField from "pages/Ui/Form/PasswordFormField";
import Button from "pages/Ui/Button/Button";
import { validateEmail, validateLogin } from "helpers/Validators";
import ErrorToastBoundary from "components/Common/ErrorHandling/ErrorToast/ErrorToastBoundary";

import styles from "./Login.module.scss";

const LoginComponent = ({
  errorStatus,
  serverError,
  loginUserAction,
  loading,
  resetLoginErrorsAction,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const history = useHistory();

  const handleFormChange = (evt, setterFunction) => {
    if (!evt) return;
    const { value } = evt.target;
    setterFunction(value);
  };

  const handleUserLogIn = () => {
    loginUserAction({ email, password }, history);
  };

  useEffect(() => {
    setIsFormValid(validateLogin({ email, password }));
  }, [email, password]);

  return (
    <LoginContainer>
      <ErrorToastBoundary
        error={serverError}
        errorStatus={errorStatus}
        text="Something went wrong while attempting to log in. Please try again."
        onCloseToast={resetLoginErrorsAction}
      >
        <div>
          <div className={styles.header}>
            <h5 className={styles.title}>Welcome Back</h5>
            <p className={styles.subtitle}>
              Sign in to continue to Chatterbox Dashboard.
            </p>
          </div>

          <div className="mt-4">
            <Form className="form-container">
              <RegularFormField
                id="login-input-email"
                label="Email"
                type="email"
                onChange={evt => handleFormChange(evt, setEmail)}
                validation={{
                  validator: validateEmail,
                  message: "Invalid e-mail",
                }}
                value={email}
              />
              <PasswordFormField
                id="login-input-password"
                label="Password"
                type="password"
                onChange={evt => handleFormChange(evt, setPassword)}
                validation={{ message: "Invalid password" }}
                value={password}
              />
              <Button
                id="login-button"
                loading={loading}
                onClick={handleUserLogIn}
                disabled={!isFormValid}
              >
                Log in
              </Button>
            </Form>
            <div className={styles.link}>
              <Link
                to="/forgot-password"
                href="pages-register-2"
                className="fw-medium text-primary"
                id="login-button-forgot-password"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </div>
      </ErrorToastBoundary>
    </LoginContainer>
  );
};

LoginComponent.propTypes = {
  clientError: PropTypes.any,
  serverError: PropTypes.any,
  errorStatus: PropTypes.any,
  history: PropTypes.object,
  loading: PropTypes.bool,
  loginUserAction: PropTypes.func,
  resetLoginErrorsAction: PropTypes.func,
};

const mapStateToProps = state => ({
  clientError: state.Login.clientError,
  serverError: state.Login.serverError,
  errorStatus: state.Login.errorStatus,
  loading: state.Login.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    loginUserAction: loginUser,
    resetLoginErrorsAction: resetLoginErrors,
  })(LoginComponent)
);
