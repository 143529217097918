import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";

import styles from './StatsCard.module.scss';
import IconTooltip from '../Tooltip/IconTooltip';

const StatsCard = ({
    cardTitle,
    emotion,
    tooltipIcon,
    tooltipText,
    value,
}) => {
    return (
        <Card className={styles.cardWrapper}>
            <CardBody className={[styles.cardBody, styles[emotion]].join(' ')}>
                <div className="d-flex flex-column justify-content-start align-items-center">
                    <div className={styles.cardHeader}>
                        <span className={styles.cardTitle}>{ cardTitle }</span>
                        {tooltipIcon && 
                            <IconTooltip
                                icon={ tooltipIcon }
                                text={ tooltipText }
                            />
                        }
                    </div>
                    <div className={styles.cardNumbers}>
                        <span className={styles.cardValue}>{ value }</span>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
};

StatsCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    emotion: PropTypes.string.isRequired,
    tooltipIcon: PropTypes.element,
    tooltipText: PropTypes.string,
    value: PropTypes.number,
}

StatsCard.defaultProps = {
    emotion: 'neutral'
}

export default StatsCard;