import { api, openApi } from "services";

export default {
  loginUser: async (email, password) => {
    return openApi.post("/user", { email, password, username: email });
  },
  forgetPassword: async email => {
    return openApi.post("/user/password/reset", { email });
  },
  changePassword: async ({ new_password1, new_password2 }) => {
    return openApi.post("/user/password/change", {
      new_password1,
      new_password2,
    });
  },
  setPassword: async ({ new_password1, new_password2, token, uid }) => {
    return openApi.post("/user/password/confirm", {
      new_password1,
      new_password2,
      token,
      uid,
    });
  },
};
