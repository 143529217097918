import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = email => {
  return {
    type: FORGET_PASSWORD,
    payload: { email },
  }
}

export const userForgetPasswordSuccess = () => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
  }
}

export const userForgetPasswordError = error => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: error,
  }
}

export const resetUserForgetPasswordErrors = () => ({
  type: RESET_FORGET_PASSWORD_ERROR
})
