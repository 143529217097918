import React, { useState } from 'react';
import LivePracticeNumberChart from './LivePracticeNumberChart';
import ChartCard from 'components/Common/Card/CardChart';
import LivePracticeTimeChart from './LivePracticeTimeChart';

const LivePracticeCompletion = () => {
    const [selectedChartKey, setSelectedChartKey] = useState('# live practices');

    const charts = { 
        '# live practices': <LivePracticeNumberChart />,
        'time': <LivePracticeTimeChart />
    };

    return (
        <ChartCard 
            cardTitle="Live Practice Completion"
            charts={Object.keys(charts)}
            selectedChart={selectedChartKey}
            onSelectChart={setSelectedChartKey}
            id="live-practice-completion"
            multipleCharts
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            { charts[selectedChartKey] }
        </ChartCard>
    )
};

export default LivePracticeCompletion;