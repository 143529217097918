import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import AuthenticationService from '../../../services/Authentication';

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, apiError } from "./actions"
import { USER_TOKEN } from "constants/general";
import { getProfile } from "../profile/actions";

function* loginUser({ payload }) {
  const { user, history } = payload;
  try {
    const { data } = yield call(
      AuthenticationService.loginUser,
      user.email,
      user.password
    );
    yield put(loginSuccess());
    localStorage.setItem(USER_TOKEN, data.key);
    yield put(getProfile());
    history.push("/");
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(USER_TOKEN);
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
