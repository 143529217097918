import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './Caret.module.scss';

const Caret = ({ sortOrder }) => {
    const [order, setOrder] = useState('');

    useEffect(() => {
        setOrder(sortOrder);
    }, [sortOrder])
 
    return (
        <div className={styles.arrowsWrapper}>
            <div className={[styles.arrowUp, order === 'asc' && styles.selected].join(' ')}/>
            <div className={[styles.arrowDown, order === 'desc' && styles.selected].join(' ')}/>
        </div>
    )
}

Caret.propTypes = {
    sortOrder: PropTypes.string,
};

Caret.defaultProps = {
    sortOrder: '',
}

export default Caret;