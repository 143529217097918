import {
    API_SUCCESS,
    API_FAIL,
    GET_INDIVIDUALS_DATA,
    GET_EARNING_DATA,
    LOADING_INDIVIDUALS_DATA
} from "./actionType";

export const loadingIndividualsData = (payload) => ({
    type: LOADING_INDIVIDUALS_DATA,
    payload
});

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getIndividualsData = () => ({
    type: GET_INDIVIDUALS_DATA,
});

/** Earning chart data */
export const getEarningChartsData = (month) => ({
    type: GET_EARNING_DATA,
    payload: month
})
