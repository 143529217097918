import { combineReducers } from "redux"

// Authentication
import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import Dashboard from "./dashboard/reducer";
import Individuals from "./individuals/reducer";
import ManageLicences from './manageLicences/reducer';

const rootReducer = combineReducers({
  Login,
  ForgetPassword,
  Profile,
  Dashboard,
  Individuals,
  ManageLicences,
})

export default rootReducer
