import ChartCard from 'components/Common/Card/CardChart';
import InformationCard from 'components/Common/Card/InformationCard';
import StatsCard from 'components/Common/Card/StatsCard';
import React from 'react';
import { Col, Row } from 'reactstrap';

const Licenses = () => {
    return (
        <ChartCard 
            cardTitle="Licenses in total:"
            count={50}
        >
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <InformationCard 
                                cardTitle='Active'
                                emotion='positive'
                                tooltipIcon={<i className="mdi mdi-information" />}
                                tooltipText="Students"
                                value={36}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <StatsCard 
                                cardTitle="Completed"
                                value={31}
                            />
                        </Col>
                        <Col xl={6}>
                            <StatsCard 
                                cardTitle="Graduated"
                                value={5}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <InformationCard 
                            cardTitle='Inactice'
                            emotion='negative'
                            tooltipIcon={<i className="mdi mdi-information" />}
                            tooltipText="Students"
                            value={10}
                        />
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <StatsCard 
                                cardTitle="Unsuccessful"
                                value={1}
                            />
                        </Col>
                        <Col xl={6}>
                            <StatsCard 
                                cardTitle="Expired"
                                value={9}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ChartCard>
    )
};

export default Licenses;