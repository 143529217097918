import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

import styles from './UserAvatar.module.scss';
import { validateUrl } from 'helpers/Validators';
import { getInitials } from 'helpers/string_helpers';

const UserAvatar = ({ imageUrl, loading, userName }) => (
    loading
        ? <Spinner/>
        : (
          <>
            {
              validateUrl(imageUrl)
              ? <img className={["rounded-circle",  styles["header-profile-user"]].join(' ')} src={imageUrl} alt="Header Avatar"/>
              : <div className={["rounded-circle",  styles["header-profile-user"], styles["with-initials"]].join(' ')}><h5 className={styles["initials"]}>{getInitials(userName)}</h5></div>
            }

          </>
        )
)

UserAvatar.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
}

UserAvatar.defaultProps = {
  imageUrl: '',
  userName: '',
  loading: false,
}

export default UserAvatar;