import axios from "axios";
import { USER_TOKEN } from "constants/general";

export const api = axios.create({
  baseURL: "https://vq4g9fus0e.execute-api.eu-west-1.amazonaws.com/dev",
});

api.interceptors.request.use(config => {
  const newHeaders = {
    ...config.headers,
    authorization: `Token ${window.localStorage.getItem(USER_TOKEN)}`,
  };
  const configWithAuthorization = { ...config, headers: newHeaders };

  return configWithAuthorization;
});

export const openApi = axios.create({
  baseURL: "https://vq4g9fus0e.execute-api.eu-west-1.amazonaws.com/dev",
});
