import { isUnauthorizedError } from "helpers/api_helper";

export const checkUnauthorizedMiddleware = () => next => action => {
    const { type, payload } = action;

    if (!type || !payload) return next(action);

    if (!hasError(type)) return next(action);

    const { error } = payload;

    if (!error) return next(action);
    
    if (error.response?.status && isUnauthorizedError(error.response?.status)) {
        window.location.href = '/login';
        return;
    }

    return next(action);
};

const hasError = type => {
    const errorActions = ['ERROR', 'FAIL'];

    return errorActions.reduce((acc, cv) => {
        return acc || type.includes(cv)
    }, false)
}