export const IndividualsTableAvailableFields = Object.freeze({
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  contract: "contract",
  bookingFrequency: "bookingFrequency",
  licenceType: "licence_type",
  status: "status",
  courseLanguage: "courseLanguage",
  courseLevel: "courseLevel",
  startDate: "startDate",
  expiryDate: "expiryDate",
  introductoryClass: "introductory_class",
  currentLesson: "currentLesson",
  expectedLesson: "expectedlesson",
  engagementStatus: "engagementstatus",
  averageCompletion: "averageCompletion",
  livePracticesCompleted: "livePracticesCompleted",
  timeSelfStudy: "timeSelfStudy",
  timeLiveClass: "timeLiveClass",
  totalStudy: "totalStudy",
});

export const filterOnlyAvailableFields = raw => {
  return raw.map(rawData => {
    return Object.keys(IndividualsTableAvailableFields).reduce((acc, key) => {
      return { ...acc, [key]: rawData[IndividualsTableAvailableFields[key]] };
    }, {});
  });
};

export const FilterableFields = Object.freeze({
  contract: "Contract",
  courseLanguage: "Language",
  courseLevel: "Level",
  // 'studyPace': 'Study pace',
  startDate: "Onboarding date",
  expiryDate: "Expiry date",
});
