import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './RegularFormField.module.scss';

const RegularFormField = ({ id, label, type, onChange, validation, value  }) => {
    const [valid, setValid] = useState(true);

    const handleUserTyping = (evt) => {
        onChange(evt);
        clearValidationError();
    }

    const checkValidation = (evt) => {
        const value = evt.target.value;

        const { validator } = validation;
        if (!validator) return;

        setValid(validator(value));
    };

    const clearValidationError = () => setValid(true);

    return (
        <div className={[styles["field-wrapper"], !valid && styles.invalidField].join(' ')}>
            <label htmlFor={id}>{label}</label>
            <input id={id} type={type} onChange={handleUserTyping} onBlur={(evt) => checkValidation(evt)} value={value}/>
            { !valid && <p className={styles.invalidMessage}>{validation.message}</p>}
        </div>
    )
};

RegularFormField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    validation: PropTypes.object,
    value: PropTypes.string.isRequired,
}

export default RegularFormField;