import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// actions
import {
  userForgetPassword,
  resetUserForgetPasswordErrors,
} from "../../store/actions";

import Form from "../Ui/Form/Form";

// import images
import RegularFormField from "pages/Ui/Form/RegularFormField";
import Button from "pages/Ui/Button/Button";
import { validateEmail } from "helpers/Validators";
import ErrorToastBoundary from "components/Common/ErrorHandling/ErrorToast/ErrorToastBoundary";

import styles from "./ForgotPassword.module.scss";
import LoginContainer from "./Container";

const ForgotPassword = ({
  forgetError,
  forgetSuccess,
  userForgetPasswordAction,
  loading,
  resetUserForgetPasswordErrorsAction,
}) => {
  const [email, setEmail] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);

  const handleFormChange = (evt, setterFunction) => {
    if (!evt) return;
    const { value } = evt.target;
    setterFunction(value);
  };

  const handleUserRequest = () => {
    userForgetPasswordAction(email);
  };

  useEffect(() => {
    setIsFormValid(validateEmail(email));
  }, [email]);

  return (
    <LoginContainer>
      <ErrorToastBoundary
        error={forgetError}
        text="Something went wrong while reseting your password. Please try again."
        onCloseToast={resetUserForgetPasswordErrorsAction}
      >
        <div>
          {forgetSuccess ? (
            <>
              <div className={styles.header}>
                <h5 className={styles.title}>Forgot Password</h5>
              </div>
              <Alert color="success" role="alert">
                {" "}
                <strong>
                  Please check your email for a password reset link.
                </strong>
                <br />
                <br />
                If you do not get a link, your account may have a different
                email address. Try{" "}
                <a href="mailto:hello@chatterbox.com">asking us for help</a>.
              </Alert>
              <div className={styles.link}>
                <Link to="/login">{"< Back to the login page"}</Link>
              </div>
            </>
          ) : (
            <>
              <div className={styles.header}>
                <h5 className={styles.title}>Forgot Password</h5>
                <p className={styles.subtitle}>
                  Enter your e-mail to receive a link to reset your password.
                </p>
              </div>
              <div className="mt-4">
                <Form className="form-container">
                  <RegularFormField
                    id="forgot-password-input-email"
                    label="Email"
                    type="email"
                    onChange={evt => handleFormChange(evt, setEmail)}
                    validation={{
                      validator: validateEmail,
                      message: "Invalid e-mail",
                    }}
                    value={email}
                  />
                  <Button
                    id="forgot-password-button"
                    loading={loading}
                    onClick={handleUserRequest}
                    disabled={!isFormValid}
                  >
                    Reset
                  </Button>
                  <div className={styles.link}>
                    <Link to=".." id="back-to-login">
                      {"< Back to the login page"}
                    </Link>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
      </ErrorToastBoundary>
    </LoginContainer>
  );
};

ForgotPassword.propTypes = {
  loading: PropTypes.bool.isRequired,
  forgetSuccess: PropTypes.bool.isRequired,
  forgetError: PropTypes.any,
  userForgetPasswordAction: PropTypes.func,
  resetUserForgetPasswordErrorsAction: PropTypes.func,
};

const mapStateToProps = state => ({
  loading: state.ForgetPassword.loading,
  forgetError: state.ForgetPassword.forgetError,
  forgetSuccess: state.ForgetPassword.forgetSuccess,
});

export default withRouter(
  connect(mapStateToProps, {
    userForgetPasswordAction: userForgetPassword,
    resetUserForgetPasswordErrorsAction: resetUserForgetPasswordErrors,
  })(ForgotPassword)
);
