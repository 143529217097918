import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
// import dashboardSaga from "./dashboard/saga";
import individualsSaga from "./individuals/saga";
import manageLicencesSaga from "./manageLicences/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    // fork(dashboardSaga),
    fork(individualsSaga),
    fork(manageLicencesSaga),
  ])
}
