import { combineReducers } from "redux";
import {
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_ERRORS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAIL,
} from "./actionTypes";
import { userInformationAdapter } from "./userInformationAdapter";

const profileInitialState = {
  information: {},
  loading: false,
  error: "",
  success: "",
};

const mainReducer = (state = profileInitialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return { ...state, loading: true };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        information: { ...userInformationAdapter(action.payload) },
        loading: false,
      };
    case GET_PROFILE_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const changePasswordInitialState = {
  error: "",
  errorStatus: 0,
  success: "",
  loading: false,
};

const changePasswordReducer = (state = changePasswordInitialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, success: true };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        errorStatus: action.payload.errorStatus,
      };
    case RESET_CHANGE_PASSWORD_ERRORS:
      return {
        ...state,
        loading: changePasswordInitialState.loading,
        error: changePasswordInitialState.error,
        errorStatus: changePasswordInitialState.errorStatus,
      };
    default:
      return { ...state };
  }
};

const setPasswordInitialState = {
  error: false,
  errorStatus: 0,
  success: false,
  loading: false,
};

const setPasswordReducer = (state = setPasswordInitialState, action) => {
  switch (action.type) {
    case SET_PASSWORD:
      return { ...state, loading: true, success: false, error: false };
    case SET_PASSWORD_SUCCESS:
      return { ...state, loading: false, success: true };
    case SET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: Boolean(action.payload.error),
        errorStatus: action.payload.errorStatus,
        success: false,
      };
    case RESET_CHANGE_PASSWORD_ERRORS:
      return {
        ...state,
        loading: changePasswordInitialState.loading,
        error: changePasswordInitialState.error,
        errorStatus: changePasswordInitialState.errorStatus,
        success: false,
      };
    default:
      return { ...state };
  }
};

const profile = combineReducers({
  mainReducer,
  changePasswordReducer,
  setPasswordReducer,
});

export default profile;
