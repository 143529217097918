import React from 'react';
import { Col, Row } from 'reactstrap';
import ChartCard from 'components/Common/Card/CardChart';
import InformationCard from 'components/Common/Card/InformationCard';

const LearnersPerformance = () => {
    return (
        <ChartCard 
            cardTitle="Learners Performance"
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            <Row>
                <Col>
                    <InformationCard 
                        cardTitle='On track'
                        emotion='positive'
                        tooltipIcon={<i className="mdi mdi-information" />}
                        tooltipText="Students"
                        value={51}
                    />
                </Col>
                <Col>
                    <InformationCard 
                        cardTitle='At risk'
                        emotion='warning'
                        tooltipIcon={<i className="mdi mdi-information" />}
                        tooltipText="Students"
                        value={10}
                    />
                </Col>
                <Col>
                    <InformationCard 
                        cardTitle='Critical'
                        emotion='negative'
                        tooltipIcon={<i className="mdi mdi-information" />}
                        tooltipText="Students"
                        value={12}
                    />
                </Col>
            </Row>
        </ChartCard>
    )
};

export default LearnersPerformance;