import React from 'react';
import { Row } from 'reactstrap';
import ChartCard from 'components/Common/Card/CardChart';
import InformationCard from 'components/Common/Card/InformationCard';

const LivePractice = () => {
    return (
        <ChartCard 
            cardTitle="Live Practice"
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            <Row>
                <InformationCard 
                    cardTitle='# of live practice completion'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={56}
                />
            </Row>
            <Row>
                <InformationCard 
                    cardTitle='Avg. live practice completion per learner'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={50}
                />
            </Row>
            <Row>
                <InformationCard 
                    cardTitle='Total live practice completion'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={36}
                />
            </Row>
            <Row>
                <InformationCard 
                    cardTitle='Avg. live practice completion per learner'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={36}
                />
            </Row>
        </ChartCard>
    )
};

export default LivePractice;