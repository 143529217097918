
import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// actions
import ChangePassword from "./ChangePassword/ChangePassword";
import CardProfile from "./CardProfile/CardProfile";
import ManageLicences from "./ManageLicences/ManageLicences";

const UserProfile = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-3">
            <h3><strong>PROFILE</strong></h3>
          </Row>
          <Row className="mb-3">
            <Col lg="12">
              <CardProfile />
            </Col>
          </Row>
          {/* <Row className="mb-3">
            <Col lg="12">
              <ManageLicences/>
            </Col>
          </Row> */}
          <Row className="mb-3">
            <Col lg="12">
              <ChangePassword />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
