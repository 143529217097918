import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_FORGET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  forgetSuccess: false,
  forgetError: null,
  loading: false,
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccess: false,
        forgetError: null,
        loading: true,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccess: true,
        loading: false,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload, forgetSuccess: false, loading: false }
      break
    case RESET_FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: null }
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
