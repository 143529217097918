import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { ReactComponent as DashboardIcon } from '../../assets/images/dashboard_icon.svg';
import { ReactComponent as IndividualsIcon } from '../../assets/images/individuals_icon.svg';

class SidebarContent extends Component {

  render() {
    return (
      <SimpleBar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li>
              <NavLink to="/dashboard" id="link-to-dashboard" activeClassName="activeItem">
                <DashboardIcon />
                <span>{this.props.t("Dashboards")}</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/individuals" id="link-to-individuals" activeClassName="activeItem">
                <IndividualsIcon />
                <span>{this.props.t("Individuals")}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SimpleBar>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));