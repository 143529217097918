import { takeEvery, put, call } from "redux-saga/effects"

import AuthenticationService from '../../../services/Authentication';

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetPassword({ payload: { email } }) {
  try {
    const { data } = yield call(AuthenticationService.forgetPassword, email)
    if (data) yield put(userForgetPasswordSuccess());
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword)
}

export default forgetPasswordSaga
