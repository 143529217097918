import * as yup from 'yup';

/* Schemas */

const emailSchema = yup.string().email();
const passwordSchema = yup.string();
const loginSchema = yup.object().shape({
    email: emailSchema.required(),
    password: passwordSchema.required(),
})

const urlSchema = yup.string().url();

const changePasswordSchema = yup.object().shape({
    firstPassword: passwordSchema,
    secondPassword: yup.string().test('check-password','', (value, context) => {
        const { parent } = context;
        return parent.firstPassword === value;
    }),
})
/* Validators */

export const validateEmail = (str) => emailSchema.isValidSync(str);
export const validateLogin = (form) => loginSchema.isValidSync(form);
export const validateUrl = (str) => urlSchema.required().isValidSync(str);
export const validateChangePassword = (form) => changePasswordSchema.isValidSync(form);
