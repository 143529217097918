import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  LOGIN_RESET_ERRORS,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history }
  }
}

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  }
}

export const logoutUser = history => ({
    type: LOGOUT_USER,
    payload: { history },
})

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: {
      error,
      errorStatus: error.response ? error.response.status : 500,
    },
  }
}

export const resetLoginErrors = () => ({ type: LOGIN_RESET_ERRORS })