import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_green.css";

import styles from './DateRange.module.scss';
import { endOfDay, startOfDay } from 'date-fns';

const DateRangePicker = ({ id, handleRangeSelection, placeholder }) => {

    const handleDateRangeSelection = ([start, end]) => {
        if (!start && !end) return handleRangeSelection(null);

        if (start && !end) return handleRangeSelection({ start: startOfDay(new Date(start)), end: endOfDay(new Date(start)) });

        return handleRangeSelection({ start: startOfDay(new Date(start)), end: endOfDay(new Date(end)) });
    };

    return (
        <Flatpickr
            className={["form-control", "d-block", styles.inputWrapper].join(" ")}
            placeholder={placeholder}
            options={{
                mode: "range",
                dateFormat: "d-m-Y"
            }}
            id={id}
            onChange={(dates) => handleDateRangeSelection(dates)}
        />
    )
}

DateRangePicker.propTypes = {
    id: PropTypes.string,
    handleRangeSelection: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default DateRangePicker;