import {
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAIL,
  RESET_CHANGE_PASSWORD_ERRORS,
} from "./actionTypes";

export const getProfile = () => ({
  type: GET_PROFILE,
});

export const getProfileSuccess = profile => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profile,
  };
};

export const getProfileError = error => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  };
};

export const changePassword = ({ newPassword, confirmedNewPassword }) => ({
  type: CHANGE_PASSWORD,
  payload: {
    newPassword,
    confirmedNewPassword,
  },
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFail = error => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: {
    error,
    errorStatus: error.response ? error.response.status : 500,
  },
});

export const resetChangePasswordErrors = () => ({
  type: RESET_CHANGE_PASSWORD_ERRORS,
});

export const resetProfileFlag = () => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const setPassword = ({
  newPassword,
  confirmedNewPassword,
  token,
  uid,
}) => ({
  type: SET_PASSWORD,
  payload: {
    newPassword,
    confirmedNewPassword,
    token,
    uid,
  },
});

export const setPasswordSuccess = () => ({
  type: SET_PASSWORD_SUCCESS,
});

export const setPasswordFail = error => ({
  type: SET_PASSWORD_FAIL,
  payload: {
    error,
    errorStatus: error.response ? error.response.status : 500,
  },
});
