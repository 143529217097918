import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import ManageLicencesService from '../../services/ManageLicences';
import { assignNewLicenceFail, assignNewLicenceSuccess, getAssignedLicencesFail, getAssignedLicencesSuccess, getAvailableOrdersFail, getAvailableOrdersSuccess } from "./actions";

// Crypto Redux States
import { ASSIGN_NEW_LICENCE, GET_ASSIGNED_LICENCES, GET_AVAILABLE_ORDERS } from "./actionTypes";

const defaultValue = [
    "English | Tuition | Online | 30 min | #XXX",
    "English | Tuition | Online | 60 min | #XXX",
    "Spanish | Tuition | Online | 30 min | #XXX",
    "Spanish | Tuition | Online | 60 min | #XXX",
    "French | Tuition | Online | 30 min | #XXX",
    "French | Tuition | Online | 60 min | #XXX",    
    "English | Tuition | Online | 30 min | #XXX",
    "English | Tuition | Online | 60 min | #XXX",
    "Spanish | Tuition | Online | 30 min | #XXX",
    "Spanish | Tuition | Online | 60 min | #XXX",
    "French | Tuition | Online | 30 min | #XXX",
    "French | Tuition | Online | 60 min | #XXX", 
    "English | Tuition | Online | 30 min | #XXX",
    "English | Tuition | Online | 60 min | #XXX",
    "Spanish | Tuition | Online | 30 min | #XXX",
    "Spanish | Tuition | Online | 60 min | #XXX",
    "French | Tuition | Online | 30 min | #XXX",
    "French | Tuition | Online | 60 min | #XXX",
];

function* getAvailableOrders() {
    try {
        // const { data } = yield ManageLicencesService.getAvailableOrders();
        yield put(getAvailableOrdersSuccess(defaultValue));
    } catch (error) {
        yield put(getAvailableOrdersFail(error));
    }
}

function* getAssignedLicences() {
    try {
        const { data } = yield ManageLicencesService.getAssignedLicences();
        yield put(getAssignedLicencesSuccess(defaultValue));
    } catch (error) {
        yield put(getAssignedLicencesFail(error));
    }
}

function * assignNewLicence({ payload }) {
    try {
        const { data } = yield ManageLicencesService.assignNewLicence(payload);
        yield put(assignNewLicenceSuccess());

    } catch (error) {
        yield put(assignNewLicenceFail(error))
    }
}

export function* watchManageLicences() {
    yield takeEvery(GET_AVAILABLE_ORDERS, getAvailableOrders);
    yield takeEvery(GET_ASSIGNED_LICENCES, getAssignedLicences);
    yield takeEvery(ASSIGN_NEW_LICENCE, assignNewLicence);
}

function* manageLicencesSaga() {
    yield all([fork(watchManageLicences)]);
}

export default manageLicencesSaga;
