import React, { Component } from "react"
import { Container } from "reactstrap"
import MetaTags from 'react-meta-tags';

import IndividualsTable from "./IndividualsTable";

class Dashboard extends Component {
  constructor(props) {
    super(props)
  };
  
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Individuals | Chatterbox</title>
          </MetaTags>
          <Container fluid>
            <IndividualsTable />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard
