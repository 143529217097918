import React, { useState } from 'react';
import ChartCard from 'components/Common/Card/CardChart';
import SelfStudyRateChart from './SelfStudyRateChart';
import SelfStudyTimeChart from './SelfStudyTimeChart';

const SelfStudyCompletion = () => {
    const charts = {
        '% rate': <SelfStudyRateChart />, 
        'time': <SelfStudyTimeChart />
    };

    const [selectedChartKey, setSelectedChartKey] = useState('% rate');

    return (
        <ChartCard
            cardTitle="Self-study completion"
            charts={Object.keys(charts)}
            selectedChart={selectedChartKey}
            onSelectChart={setSelectedChartKey}
            id="self-study-completion"
            multipleCharts
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            { charts[selectedChartKey] }
        </ChartCard>
    )
};

export default SelfStudyCompletion;