export function objToCSV(arrOfObj) {
    const headers = Object.keys(arrOfObj[0]).join(',');
    const dataRows = arrOfObj
        .map(obj => {
            const sortedValues = headers.split(',').map(header => obj[header]);
            return sortedValues.join(',');
        })
        .join('\n');
    
    return [headers, dataRows].join('\n')
}

export function downloadCSV(csvData) {
    const csvFile = new Blob([csvData], { type: 'text/csv' });
    const temporaryLink = document.createElement('a');
    temporaryLink.download = "individuals_data.csv";
    const url = window.URL.createObjectURL(csvFile);
    temporaryLink.href = url;
    
    temporaryLink.style.display = 'none';
    document.body.appendChild(temporaryLink);

    temporaryLink.click();
    document.body.removeChild(temporaryLink);
}

function createTableHeaders(headersArr) {
    return headersArr.join(',');
}