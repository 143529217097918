export const individualsDataAdapter = (rawData) => {
    return rawData.map((obj, index) => {
        const flatObject = Object.keys(obj).reduce((acc, key) => {

            const isObject = typeof obj[key] === 'object';
            if (!isObject) return { ...acc, [key]: obj[key] };

            return { ...acc,  ...obj[key] }
            
        }, {});

        return { ...flatObject, randomId: generateRandomId() }
    })
}

const generateRandomId = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}