import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from "reactstrap";

import styles from './CardChart.module.scss';
import IconTooltip from '../Tooltip/IconTooltip';
import Toggle from '../Toggle/Toggle';

const ChartCard = ({
    cardTitle,
    charts,
    children,
    count,
    id,
    multipleCharts,
    selectedChart,
    onSelectChart,
    tooltipIcon,
    tooltipText,
}) => {
    return (
        <Card className={styles.cardWrapper}>
            <CardBody>
                <div className={styles.cardHeader}>
                    <div>
                        <span className={styles.cardTitle}>{ cardTitle }</span>
                        {tooltipIcon && 
                            <IconTooltip 
                                icon={ tooltipIcon }
                                text={ tooltipText }
                            />
                        }
                        {count && <span className={styles.cardCount}>{ count }</span>}
                    </div>
                    { multipleCharts && (
                        <div>
                            <Toggle 
                                id={id}
                                properties={charts} 
                                selectedProperty={selectedChart} 
                                setSelected={onSelectChart} 
                            />
                        </div>
                    )}
                </div>
                { children }
            </CardBody>
        </Card>
    )
};

ChartCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    charts: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
    count: PropTypes.number,
    id: PropTypes.string,
    multipleCharts: PropTypes.bool,
    selectedChart: PropTypes.string,
    onSelectChart: PropTypes.func,
    tooltipIcon: PropTypes.element,
    tooltipText: PropTypes.string,
}

export default ChartCard;