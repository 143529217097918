import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './PasswordFormField.module.scss';

const PasswordFormField = ({ id, label, onChange, validation, value }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [valid, setValid] = useState(true);

    const handleShowPassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleUserTyping = (evt) => {
        onChange(evt);
        checkValidation(evt);
    }

    const checkValidation = (evt) => {
        const value = evt.target.value;

        const { validator } = validation;
        if (!validator) return;

        setValid(validator(value));
    };

    return (
        <div className={[styles["field-wrapper"], !valid && styles.invalidField].join(' ')}>
            <label htmlFor={id}>{label}</label>
            <div className={styles["password-field-wrapper"]}>
                <input id={id} type={showPassword ? 'text' : 'password'} onChange={handleUserTyping} value={value}/>
                <i id={`${id}-show`} className={`mdi ${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`} onClick={handleShowPassword} />
            </div>
            { !valid && <p className={styles.invalidMessage}>{validation.message}</p>}
        </div>
    )
};

PasswordFormField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    validation: PropTypes.object,
    value: PropTypes.string.isRequired,
}

export default PasswordFormField;