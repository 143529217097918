import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_EARNING_DATA, GET_INDIVIDUALS_DATA } from "./actionType";
import { apiSuccess, apiFail, loadingIndividualsData } from "./actions";

import IndividualsService from '../../services/Individuals';

function* getIndividualsData() {
    try {
        yield put(loadingIndividualsData(true));
        const { data } = yield call(IndividualsService.getIndividualsData);
        yield put(apiSuccess(GET_INDIVIDUALS_DATA, data));
    } catch (error) {
        yield put(apiFail(GET_INDIVIDUALS_DATA, error));
    }
}


export function* watchGetIndividualsData() {
    yield takeEvery(GET_INDIVIDUALS_DATA, getIndividualsData);
}


function* dashboardSaasSaga() {
    yield all([fork(watchGetIndividualsData)],
    );
}

export default dashboardSaasSaga;
