import { isEveryPropertyDefined } from "helpers/object_helper";

export const userInformationAdapter = (rawInfo) => {
    const {
        email,
        first_name,
        last_name,
        profile_picture,
    } = rawInfo;

    if (!isEveryPropertyDefined({ email, first_name, last_name })) return;

    return {
        email,
        name: `${first_name} ${last_name}`,
        img: profile_picture || '',
    }
}