import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import styles from './Dropdown.module.scss';

const FormDropdown = ({ buttonClasses, caret, clearSelectedValue, id, label, options, placeholder, value }) => {
    const [isOpen, setOpen] = useState(false);

    const handleOpenDropdown = () => setOpen(prevState => !prevState);

    return (
        <div className={styles.wrapper}>
            {label && <label className={styles.label} htmlFor={id}>{label}</label>}
            <Dropdown
                isOpen={isOpen}
                toggle={handleOpenDropdown}
                className={styles.dropdown}
            >
                <DropdownToggle tag="button" id={id} data-toggle="dropdown" aria-expanded={isOpen} className={["form-control", styles.button, ...buttonClasses].join(' ')}>
                    { value || <p>{placeholder}</p> }
                    
                    { value && clearSelectedValue && (
                        <button
                            type="button"
                            onClick={clearSelectedValue}
                            aria-label="Close"
                            className={styles.closeButton}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    )}
                    {caret && <i className={["mdi", "mdi-chevron-down", isOpen && styles.rotateCaret].join(' ')} />}

                </DropdownToggle>
                <DropdownMenu className={styles['items-wrapper']}>
                    {options}
                </DropdownMenu>
            </Dropdown>
        </div>
    )
};

FormDropdown.propTypes = {
    buttonClasses: PropTypes.array.isRequired,
    caret: PropTypes.bool.isRequired,
    clearSelectedValue: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.any,
    placeholder: PropTypes.any,
    value: PropTypes.any,
}

FormDropdown.defaultProps = {
    buttonClasses: [],
    caret: false,
    clearSelectedValue: () => {},
    placeholder: '',
}

export default FormDropdown;