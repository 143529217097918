/** Get Dashboard Chart data */
export const GET_AVAILABLE_ORDERS = "GET_AVAILABLE_ORDERS";
export const GET_AVAILABLE_ORDERS_SUCCESS = "GET_AVAILABLE_ORDERS_SUCCESS";
export const GET_AVAILABLE_ORDERS_FAIL = "GET_AVAILABLE_ORDERS_FAIL";

export const GET_ASSIGNED_LICENCES = "GET_ASSIGNED_LICENCES";
export const GET_ASSIGNED_LICENCES_SUCCESS = "GET_ASSIGNED_LICENCES_SUCCESS";
export const GET_ASSIGNED_LICENCES_FAIL = "GET_ASSIGNED_LICENCES_FAIL";

export const ASSIGN_NEW_LICENCE = "ASSIGN_NEW_LICENCE";
export const ASSIGN_NEW_LICENCE_SUCCESS = "ASSIGN_NEW_LICENCE_SUCCESS";
export const ASSIGN_NEW_LICENCE_FAIL = "ASSIGN_NEW_LICENCE_FAIL";

export const RESET = "RESET_MANAGE_LICENCES";