import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import UserAvatar from 'components/Common/UserAvatar/UserAvatar';

const CardProfile = ({ avatarImgUrl, email, loading, userName }) => {
    return (
        <>
            <h4 className="card-title mb-2">Your information</h4>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3 d-flex align-items-center">
                      <UserAvatar loading={loading} userName={userName} imageUrl={avatarImgUrl} />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{userName}</h5>
                        <p className="mb-1">{email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
        </>
    )
}

CardProfile.propTypes = {
    avatarImgUrl: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};

CardProfile.defaultProps = {
    avatarImgUrl: '',
    email: '',
    userName: '',
    loading: false,
};

const mapStateToProps = state => ({
    userName: state.Profile.mainReducer.information.name,
    email: state.Profile.mainReducer.information.email,
    avatarImgUrl: state.Profile.mainReducer.information.img,
    loading: state.Profile.mainReducer.loading,
  });

export default connect(mapStateToProps, null)(CardProfile);