import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { CHANGE_PASSWORD, GET_PROFILE, SET_PASSWORD } from "./actionTypes";
import {
  getProfileSuccess,
  getProfileError,
  changePasswordSuccess,
  changePasswordFail,
  setPasswordSuccess,
  setPasswordFail,
} from "./actions";

import ProfileService from "../../../services/Profile";
import AuthenticationService from "../../../services/Authentication";

function* getProfile() {
  try {
    const { data } = yield call(ProfileService.getProfile);
    yield put(getProfileSuccess(data));
  } catch (error) {
    yield put(getProfileError(error));
  }
}

function* changePassword(action) {
  try {
    const { data } = yield call(AuthenticationService.changePassword, {
      new_password1: action.payload.newPassword,
      new_password2: action.payload.confirmedNewPassword,
    });
    yield put(changePasswordSuccess());
  } catch (error) {
    yield put(changePasswordFail(error));
  }
}

function* setPassword(action) {
  try {
    yield call(AuthenticationService.setPassword, {
      new_password1: action.payload.newPassword,
      new_password2: action.payload.confirmedNewPassword,
      token: action.payload.token,
      uid: action.payload.uid,
    });
    yield put(setPasswordSuccess());
  } catch (error) {
    yield put(setPasswordFail(error));
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getProfile);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(SET_PASSWORD, setPassword);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
