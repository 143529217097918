import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ checked, dataField, id, onCheck, text }) => {
    const [check, setCheck] = useState(checked);

    const handleCheckboxSelection = (evt) => {
        setCheck(evt.target.checked);
        onCheck({dataField: evt.target.value, checked: evt.target.checked})
    }
    return (
        <div className="form-check mb-3">
            <input
                className="form-check-input"
                type="checkbox"
                value={dataField}
                id={id}
                onChange={(evt) => handleCheckboxSelection(evt)}
                checked={check}
            />
            <label
                className="form-check-label"
                htmlFor={id}
            >
                { text }
            </label>
        </div>
    )
};

Checkbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    dataField: PropTypes.string.isRequired,
    id: PropTypes.string,
    onCheck: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
    checked: false,
    dataField: '',
    onCheck: () => {},
    text: '',
}

export default Checkbox;