import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';
import { Spinner } from "reactstrap";

const Button = ({
  id, children, type, outfit, onClick, disabled, loading, nowrap,
}) => {
  const buttonOutfit = outfit ? styles[outfit] : styles.regularButton;

  return (
    <button
      className={[`${styles.button} ${buttonOutfit}`, loading && styles.loading, nowrap && styles.nowrap].join(' ')}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      id={id}
    >
      {loading ? <Spinner className={styles.spinner}> Loading </Spinner> : children}
    </button>
  );
};

const propTypes = {
  id: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any,
  type: PropTypes.string,
  outfit: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nowrap: PropTypes.bool,
};

Button.propTypes = propTypes;

Button.defaultProps = {
  loading: false,
  children: '',
  type: 'button',
  outfit: 'regularButton',
  disabled: false,
  nowrap: false,
};

export default Button;
