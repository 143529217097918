import React from 'react';
import ChartCard from 'components/Common/Card/CardChart';
import InformationCard from 'components/Common/Card/InformationCard';
import { Row } from 'reactstrap';

const SelfStudy = () => {
    return (
        <ChartCard 
            cardTitle="Self-study"
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            <Row>
                <InformationCard 
                    cardTitle='Total self-study completion'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={31}
                    percentage={11}
                />
            </Row>
            <Row>
                <InformationCard 
                    cardTitle='Avg. self-study completion per learner'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={-12}
                    percentage={-2}
                />
            </Row>
            <Row>
                <InformationCard 
                    cardTitle='Avg. lesson assignment completion'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={-4}
                    percentage={-23}
                />
            </Row>
            <Row>
                <InformationCard 
                    cardTitle='Avg. lesson assingment completion per learner'
                    emotion='neutral'
                    tooltipIcon={<i className="mdi mdi-information" />}
                    tooltipText="Students"
                    value={36}
                    percentage={40}
                />
            </Row>
        </ChartCard>
    )
};

export default SelfStudy;