import { combineReducers } from "redux";
import { ASSIGN_NEW_LICENCE, ASSIGN_NEW_LICENCE_FAIL, ASSIGN_NEW_LICENCE_SUCCESS, GET_ASSIGNED_LICENCES, GET_ASSIGNED_LICENCES_FAIL, GET_ASSIGNED_LICENCES_SUCCESS, GET_AVAILABLE_ORDERS, GET_AVAILABLE_ORDERS_FAIL, GET_AVAILABLE_ORDERS_SUCCESS, RESET } from "./actionTypes";

const availableOrdersInitialState = {
    availableOrders: [],
    isLoading: false,
};

const AvailableOrdersReducer = (state = availableOrdersInitialState, action) => {
    switch (action.type) {
        case GET_AVAILABLE_ORDERS:
            return { ...state, isLoading: true };
        case GET_AVAILABLE_ORDERS_SUCCESS:
            return { ...state, availableOrders: action.payload, isLoading: false };
            case GET_AVAILABLE_ORDERS_FAIL:
                return { ...state, isLoading: false };
        case RESET:
            return availableOrdersInitialState;
        default:
            return state;
    }
}

const assignReducerInitialState = {
    isSuccess: false,
    isLoading: false,
    // assignedLicences: [],
    assignedLicences: [
        {order: "English | Tuition | Online | 60 min | #XXX", userName: "Otavio Bonassi", email: "otavio@chatterbox.io", avatarImgUrl: "", status: "Active", daysLeft: "180"},
        {order: "Spanish | Tuition | Online | 60 min | #XXX", userName: "Jane Doe", email: "jane@chatterbox.io", avatarImgUrl: "", status: "Pending", daysLeft: "-"},
    ],
};

const AssignedReducer = (state = assignReducerInitialState, action) => {
    switch (action.type) {
        case GET_ASSIGNED_LICENCES:
            return { ...state, isLoading: true};
        case GET_ASSIGNED_LICENCES_SUCCESS:
            return { ...state, assignedLicenses: action.payload, isLoading: false };
            case GET_ASSIGNED_LICENCES_FAIL:
                return { ...state, isLoading: false };
        case ASSIGN_NEW_LICENCE:
            return { ...state, isLoading: true };
        case ASSIGN_NEW_LICENCE_SUCCESS:
            return { ...state, isSuccess: true, isLoading: false };
            case ASSIGN_NEW_LICENCE_FAIL:
                return { ...state, isLoading: false };
        case RESET:
            return assignReducerInitialState;
        default:
            return state;
    }
}

const rootManageLicencesReducer = combineReducers({
    AvailableOrders: AvailableOrdersReducer,
    Assigned: AssignedReducer,
})

export default rootManageLicencesReducer;