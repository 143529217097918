import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

export default class CarouselPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={8}>
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="p-4 my-auto">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center">
                        <div dir="ltr">
                          <Carousel showThumbs={false} className="slider_css">
                            <div>
                              <div className="item">
                                <div className="py-3">
                                  <p className="font-size-16 mb-4">
                                    &quot;BNP Paribas Portugal employees will
                                    benefit from a great opportunity to boost
                                    their language skills, while having a
                                    positive impact.&ldquo;
                                  </p>
                                  <div>
                                    <p className="font-size-14 mb-0">
                                      — HERVÉ REYNAUD, HEAD OF HR, BNP PARIBAS
                                      PORTUGAL
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="item">
                                <div className="py-3">
                                  <p className="font-size-16 mb-4">
                                    &quot;My coach helps me practice real-life
                                    scenarios in both a safe and challenging
                                    way. He’s such a nice person and we’ve
                                    gotten along since day one.&ldquo;
                                  </p>
                                  <div>
                                    <p className="font-size-14 mb-0">
                                      - DIEGO SANTOS, ENGLISH LEARNER
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="item">
                                <div className="py-3">
                                  <p className="font-size-16 mb-4">
                                    &quot;Engaging and practical lessons served
                                    as an excellent foundation to progressing my
                                    French language skills, whilst also
                                    preparing me for the next stage in being
                                    able to apply what I’ve learnt into business
                                    scenarios.&ldquo;
                                  </p>
                                  <div>
                                    <p className="font-size-14 mb-0">
                                      — SAMUEL GRANT - CONSULTANT FINANCIALS,
                                      FTI CONSULTING
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}
