import {
    ASSIGN_NEW_LICENCE,
    ASSIGN_NEW_LICENCE_FAIL,
    ASSIGN_NEW_LICENCE_SUCCESS,
    GET_AVAILABLE_ORDERS, GET_AVAILABLE_ORDERS_FAIL, GET_AVAILABLE_ORDERS_SUCCESS, RESET, GET_ASSIGNED_LICENCES, GET_ASSIGNED_LICENCES_SUCCESS, GET_ASSIGNED_LICENCES_FAIL,
} from "./actionTypes";

export const getAvailableOrders = () => ({
    type: GET_AVAILABLE_ORDERS,
});

export const getAvailableOrdersSuccess = (payload) => ({
    type: GET_AVAILABLE_ORDERS_SUCCESS,
    payload,
});

export const getAvailableOrdersFail = (error) => ({
    type: GET_AVAILABLE_ORDERS_FAIL,
    error,
});

export const getAssignedLicences = () => ({
    type: GET_ASSIGNED_LICENCES
})

export const getAssignedLicencesSuccess = (payload) => ({
    type: GET_ASSIGNED_LICENCES_SUCCESS,
    payload
})

export const getAssignedLicencesFail = (error) => ({
    type: GET_ASSIGNED_LICENCES_FAIL,
    error
})

export const assignNewLicence = (payload) => ({
    type: ASSIGN_NEW_LICENCE,
    payload
})

export const assignNewLicenceSuccess = () => ({
    type: ASSIGN_NEW_LICENCE_SUCCESS,
})

export const assignNewLicenceFail = (error) => ({
    type: ASSIGN_NEW_LICENCE_FAIL,
    error,
})

export const resetManageLicences = () => ({
    type: RESET,
})