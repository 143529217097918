import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import { changePassword, resetChangePasswordErrors } from 'store/actions';

import Form from '../../Ui/Form/Form';
import PasswordFormField from "../../Ui/Form/PasswordFormField";
import { validateChangePassword } from 'helpers/Validators';
import Button from 'pages/Ui/Button/Button';

import styles from './ChangePassword.module.scss';
import ErrorToastBoundary from 'components/Common/ErrorHandling/ErrorToast/ErrorToastBoundary';

const ChangePassword = ({ changePasswordAction, error, errorStatus, loading, resetChangePasswordErrorsAction }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmedNewPassword, setConfirmedNewPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);

    useEffect(() => {
        setPasswordMatch(true);
    }, [confirmedNewPassword])

    const handleFormChange = (evt, setterFunction) => {
        if (!evt) return;
        const { value } = evt.target;

        setterFunction(value);
    }

    const handleChangePassword = () => {
        const isMatch = validateChangePassword({ 
            firstPassword: newPassword,
            secondPassword: confirmedNewPassword,
        });
        setPasswordMatch(isMatch);

        if (isMatch) changePasswordAction({ newPassword, confirmedNewPassword });
    }

    return (
        <>
            <h4 className="card-title mb-2">Change your password</h4>
            <Card>
                <CardBody>
                    <div className="w-50">
                        <ErrorToastBoundary
                            error={error} 
                            errorStatus={errorStatus} 
                            text="Something went wrong while changing your password. Please try again."
                            onCloseToast={resetChangePasswordErrorsAction}
                        >
                            <Form className={["form-container", styles.wrapper].join(' ')}>
                                <PasswordFormField
                                    id="new-password"
                                    label="New password"
                                    type="password"
                                    onChange={(evt) => handleFormChange(evt, setNewPassword)}
                                    validation={{message: 'Invalid password'}}
                                    value={newPassword}
                                />
                                <PasswordFormField
                                    id="validate-new-password"
                                    label="Confirm your new password"
                                    type="password"
                                    onChange={(evt) => handleFormChange(evt, setConfirmedNewPassword)}
                                    validation={{message: 'Invalid password'}}
                                    value={confirmedNewPassword}
                                />
                                {!passwordMatch && <p className={styles.invalidMessage}>Passwords don&apos; match. Please check and try again.</p>}
                                <Button loading={loading} onClick={handleChangePassword}>Change password</Button>
                            </Form>
                        </ErrorToastBoundary>
                    </div>
                </CardBody>
            </Card>
        </>
        
    )
};

const mapStateToProps = state => ({
    loading: state.Profile.changePasswordReducer.loading,
    error: state.Profile.changePasswordReducer.error,
    errorStatus: state.Profile.changePasswordReducer.errorStatus,
  })

ChangePassword.propTypes = {
    changePasswordAction: PropTypes.func.isRequired,
    resetChangePasswordErrorsAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any.isRequired,
    errorStatus: PropTypes.any.isRequired,
}

export default connect(mapStateToProps, {
    changePasswordAction: changePassword,
    resetChangePasswordErrorsAction: resetChangePasswordErrors,
})(ChangePassword);