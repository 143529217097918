import ChartCard from 'components/Common/Card/CardChart';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ExamCompletion = () => {
    const series = [
        {
          data: [38, 43],
        },
    ];

    const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },

        colors: ["#34c38f"],
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
            "Practice Exam",
            "Final Exam",
          ],
        },
    };

    return (
        <ChartCard 
            cardTitle="Exam completion rate"
            tooltipIcon={ <i className="mdi mdi-information" /> }
            tooltipText="Learner perfomance Learner perfomance Learner perfomance Learner perfomance Learner perfomance"
        >
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="150"
                className="apex-charts"
            />
        </ChartCard>
    )
};

export default ExamCompletion;