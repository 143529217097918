import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// actions
import { logoutUser } from '../../../store/auth/login/actions';

import { connect } from "react-redux";
import { getProfile } from "store/actions";
import UserAvatar from "components/Common/UserAvatar/UserAvatar";

const ProfileMenu = ({ t, avatarImgUrl, userName, loading, history, logoutUserAction, getProfileAction }) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (!userName) getProfileAction();
  }, [userName])

  const toggle = () => {
    setMenu(prevState => !prevState);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item p-0"
          id="header-user-dropdown"
          tag="button"
        >
          <UserAvatar loading={loading} userName={userName} imageUrl={avatarImgUrl} />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            <i className="bx bx-user font-size-16 align-middle ms-1" />
            {t("Profile")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <button className="dropdown-item" onClick={() => logoutUserAction(history)}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  avatarImgUrl: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  getProfileAction: PropTypes.func.isRequired,
  logoutUserAction: PropTypes.func.isRequired,
  userName: PropTypes.string,
}

const mapStateToProps = state => ({
  userName: state.Profile.mainReducer.information.name,
  avatarImgUrl: state.Profile.mainReducer.information.img,
  loading: state.Profile.mainReducer.loading,
})

export default withRouter(
  connect(mapStateToProps, {
    getProfileAction: getProfile,
    logoutUserAction: logoutUser
  })(withTranslation()(ProfileMenu))
)
