import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, Spinner } from 'reactstrap';

import UserAvatar from 'components/Common/UserAvatar/UserAvatar';
import Button from 'pages/Ui/Button/Button';

import styles from './ManageLicences.module.scss';
import AssignLicenceModal from './AssignLicenceModal';
import { getAssignedLicences } from 'store/manageLicences/actions';

const ManageLicences = ({ assignedLicences, getAssignedLicencesAction, isLoading }) => {
    const [isAssignLicenceModalOpen, setIsAssignLicenceModalOpen] = useState(false);

    useEffect(() => {
      getAssignedLicencesAction();
    }, []);

    return (
        <div className={styles['manage-licences__wrapper']}>
            <h4 className="card-title mb-2">Manage your licences</h4>
            {isLoading 
              ? <Spinner/>
              : (
                Boolean(assignedLicences.length) && assignedLicences.map((licence) => {
                  return (
                    <Card key={Math.random()} className={styles['manage-licences__card']}>
                      <CardBody>
                        <div className={styles['manage-licences__licence']}>
                          <UserAvatar loading={false} userName={licence.userName} imageUrl={licence.avatarImgUrl} />
                          <h5 className={styles['manage-licences__licence__name']}>{licence.userName}</h5>
                          <p className={styles['manage-licences__licence__email']}>{licence.email}</p>
                          <p className={styles['manage-licences__licence__order']}>{licence.order}</p>
                          <p className={styles['manage-licences__licence__status']}>
                            <span className={styles[licence.status.toLowerCase()]}>{licence.status}</span>
                          </p>
                          <p className={styles['manage-licences__licence__days-left']}>{licence.daysLeft}</p>
                          <div className={styles['manage-licences__licence__action-buttons']}>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )
                })
              )
            }
            <div className={styles['manage-licences__assign-button']}>
              <Button id="assign-new-licence" onClick={() => setIsAssignLicenceModalOpen(true)}>Assign new licence</Button>
            </div>
            {isAssignLicenceModalOpen && <AssignLicenceModal isModalOpen={isAssignLicenceModalOpen} handleCloseModal={() => setIsAssignLicenceModalOpen(false)}/>}
        </div>
    )
}

ManageLicences.propTypes = {
  assignedLicences: PropTypes.array.isRequired,
  getAssignedLicencesAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  assignedLicences: state.ManageLicences.Assigned.assignedLicences,
  isLoading: state.ManageLicences.Assigned.isLoading,
});

export default connect(mapStateToProps, {
  getAssignedLicencesAction: getAssignedLicences,
})(ManageLicences);