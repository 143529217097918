import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Spinner, Toast, ToastBody, ToastHeader } from "reactstrap";

import styles from "./ErrorToastBoundary.module.scss";

const ErrorToastBoundary = ({ children, error = null, onCloseToast, text }) => {
  const [show, setShow] = useState(error);

  useEffect(() => {
    setShow(error);
  }, [error]);

  return (
    <>
      {children}
      {show && (
        <Toast isOpen={show} className={styles["error-toast--wrapper"]}>
          <ToastHeader
            icon={<Spinner type="grow" size="sm" color="danger" />}
            toggle={onCloseToast}
          >
            Ops, something went wrong
          </ToastHeader>
          <ToastBody>{text}</ToastBody>
        </Toast>
      )}
    </>
  );
};

ErrorToastBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  error: PropTypes.bool.isRequired,
  onCloseToast: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default ErrorToastBoundary;
