import {
    API_SUCCESS,
    API_FAIL,
    GET_INDIVIDUALS_DATA,
    GET_EARNING_DATA,
    LOADING_INDIVIDUALS_DATA,
} from "./actionType";
import { individualsDataAdapter } from "./adapters";

const INIT_STATE = {
    loading: true,
    individualsData: [],
    earningChartData: []
};

const Individuals = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOADING_INDIVIDUALS_DATA: 
            return { ...state, loading: true }
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_INDIVIDUALS_DATA:
                    return {
                        ...state,
                        loading: false,
                        individualsData: individualsDataAdapter(action.payload.data)
                    };

                case GET_EARNING_DATA:
                    return {
                        ...state,
                        loading: false,
                        earningChartData: action.payload.data
                    };
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_INDIVIDUALS_DATA:
                    return {
                        ...state,
                        loading: false,
                        sellingDataError: action.payload.error
                    };

                case GET_EARNING_DATA:
                    return {
                        ...state,
                        loading: false,
                        earningChartDataError: action.payload.error
                    };


                default:
                    return state;
            }
        default:
            return state;
    }
}


export default Individuals;