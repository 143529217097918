import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// actions
import { resetChangePasswordErrors, setPassword } from "../../store/actions";

import Form from "../Ui/Form/Form";

// import images
import PasswordFormField from "pages/Ui/Form/PasswordFormField";
import Button from "pages/Ui/Button/Button";
import { validateChangePassword } from "helpers/Validators";
import ErrorToastBoundary from "../../components/Common/ErrorHandling/ErrorToast/ErrorToastBoundary";
import useQuery from "hooks/useQuery";

import styles from "./SetPassword.module.scss";
import { USER_TOKEN } from "constants/general";
import LoginContainer from "./Container";

const SetPassword = ({
  loading,
  resetChangePasswordErrorsAction,
  setPasswordAction,
  setPasswordSuccess,
  setPasswordError,
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [isPasswordMatch, setPasswordMatch] = useState(false);
  const queryParams = useQuery();
  const token = queryParams.get("token");
  const uid = queryParams.get("uid");

  const handleFormChange = (evt, setterFunction) => {
    if (!evt) return;
    const { value } = evt.target;
    setterFunction(value);
  };

  const handleChangePassword = () => {
    if (isPasswordMatch)
      setPasswordAction({ newPassword, confirmedNewPassword, token, uid });
  };

  const checkPassword = () => {
    if (!newPassword || !confirmedNewPassword) return setPasswordMatch(false);

    const isMatch = validateChangePassword({
      firstPassword: newPassword,
      secondPassword: confirmedNewPassword,
    });
    setPasswordMatch(isMatch);
  };

  useEffect(() => {
    checkPassword();
  }, [confirmedNewPassword]);

  useEffect(() => {
    setToken();
  }, []);

  const setToken = () => {
    if (token) localStorage.setItem(USER_TOKEN, token);
  };

  return (
    <LoginContainer>
      <ErrorToastBoundary
        error={setPasswordError}
        text="Something went wrong while setting your password. Please try again."
        onCloseToast={resetChangePasswordErrorsAction}
      >
        <div>
          {setPasswordSuccess ? (
            <>
              <Alert color="success" role="alert">
                {" "}
                <strong>Everything is set.</strong>
                <br />
                <br />
                You should be able to use your password now.
              </Alert>
              <div className={styles.link}>
                <Link to="/login">{"< Back to the login page"}</Link>
              </div>
            </>
          ) : (
            <>
              <div className={styles.header}>
                <h5 className={styles.title}>Set Password</h5>
                <p className={styles.subtitle}>
                  Create your password to access Chatterbox Dashboard.
                </p>
              </div>
              <div className="mt-4">
                <Form className="form-container">
                  <PasswordFormField
                    id="password"
                    label="Password"
                    type="password"
                    onChange={evt => handleFormChange(evt, setNewPassword)}
                    validation={{ message: "Invalid password" }}
                    value={newPassword}
                  />
                  <PasswordFormField
                    id="confirm-password"
                    label="Confirm password"
                    type="password"
                    onChange={evt =>
                      handleFormChange(evt, setConfirmedNewPassword)
                    }
                    validation={{ message: "Invalid password" }}
                    value={confirmedNewPassword}
                  />
                  <Button
                    id="create-password-button"
                    loading={loading}
                    onClick={handleChangePassword}
                    disabled={!isPasswordMatch}
                  >
                    Create password
                  </Button>
                  <div className={styles.link}>
                    <Link to="/login" id="back-to-login">
                      {"< Back to the login page"}
                    </Link>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
      </ErrorToastBoundary>
    </LoginContainer>
  );
};

SetPassword.propTypes = {
  loading: PropTypes.bool.isRequired,
  setPasswordSuccess: PropTypes.bool.isRequired,
  setPasswordError: PropTypes.bool.isRequired,
  setPasswordAction: PropTypes.func.isRequired,
  resetChangePasswordErrorsAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.Profile.setPasswordReducer.loading,
  setPasswordError: state.Profile.setPasswordReducer.error,
  setPasswordSuccess: state.Profile.setPasswordReducer.success,
});

export default withRouter(
  connect(mapStateToProps, {
    setPasswordAction: setPassword,
    resetChangePasswordErrorsAction: resetChangePasswordErrors,
  })(SetPassword)
);
