import React, { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"

const SelfStudyChart = () => {
    const [lessons, setLessons] = useState([]);
    const [completionPercentage, setCompletionPercentage] = useState([]);

    const buildXAxis = () => {
        return ["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9", "L10", "L11", "L12", "L13", "L14", "L15", "L16", "L17", "L18", "L19", "L20"];
    }

    const buildYAxis = () => {
        if (!lessons) return;
        return lessons.map(() => Math.random()*100);
    }

    useEffect(() => {
        const xAxis = buildXAxis();
        setLessons(xAxis);
    }, [])

    useEffect(() => {
        const yAxis = buildYAxis();
        setCompletionPercentage(yAxis);
    }, [lessons])

    const data = {
        labels: lessons,
        datasets: [
          {
            label: "Completion %",
            backgroundColor: "rgba(52, 195, 143, 0.8)",
            borderColor: "rgba(52, 195, 143, 0.8)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
            hoverBorderColor: "rgba(52, 195, 143, 0.9)",
            data: completionPercentage,
          },
        ],
      }
  
      const option = {
        scales: {
          dataset: [
            {
              barPercentage: 0.4,
            },
          ],
        },
      }

    return (
      <React.Fragment>
        <Bar width={474} height={300} data={data} options={option} />
      </React.Fragment>
    )
}

export default SelfStudyChart
