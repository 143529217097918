import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './filters.module.scss';
import { Search } from 'react-bootstrap-table2-toolkit';
import DateRangePicker from 'components/Common/Datepicker/DateRange';
import Checkbox from 'components/Common/Checkbox/Checkbox';
import FormDropdown from 'components/Common/Dropdown/Dropdown';

const Filters = ({ fields, onFilter, ...props }) => {
    const [availableFilters, setAvailableFilters] = useState(fields);
    const [selectedFilters, setSelectedFilters] = useState(new Map());

    useEffect(() => {
        if (fields.size) setAvailableFilters(fields);
    }, [fields])

    const handleCheckboxSelection = (field, evt) => {
        const { dataField: optionName, checked} = evt;
        const filters = selectedFilters;
        const filterValue = filters.get(field);

        if (!filterValue) {
            filters.set(field, [optionName]);
            setSelectedFilters(filters);
            return onFilter(filters);
        };

        if (!checked) {
            const value = filterValue.filter(option => option !== optionName);
            filters.set(field, value);
        } else {
            filters.set(field, [...filterValue, optionName]);
        }

        setSelectedFilters(filters);
        return onFilter(filters);

    }

    const handleDateRangeSelection = (filterData) => {
        const filter = selectedFilters;

        const {field, value} = filterData;

        if (!value) {
            filter.delete(field);
        } else {
            filter.set(field, [value]);
        }

        onFilter(filter);
        setSelectedFilters(filter);
    }
    
    if (!availableFilters.size) return null;

    const { SearchBar } = Search;

    return (
            <div className={styles.wrapper}>
                <SearchBar id="filter-search-bar" className={styles.searchBar}  { ...props } placeholder="Search for any text"/>
                <div className={styles.filters__wrapper}>
                    {Array.from(availableFilters.values()).map((field, index) => {
                        if (field.field.includes("Date")) return (
                            <DateRangePicker 
                                key={field.title + "date-range" + index} 
                                placeholder={field.title} 
                                handleRangeSelection={(dates => handleDateRangeSelection({ ...field, value: dates }))}
                                id={`filter-date-range-${field.field}`}
                            />
                        );

                        return (
                            <FormDropdown
                                id={`filter-select-${field.field}`}
                                key={field.title + "select" + index}
                                caret
                                options={ field.data.map((value, index) => (
                                    <Checkbox 
                                        checked={selectedFilters.get(field.field)?.includes(value)}
                                        key={value + index} 
                                        dataField={value}
                                        text={value}
                                        id={`filter-select-${field.field}-${value}`}
                                        onCheck={handleCheckboxSelection.bind(this, field.field)}
                                    />
                                ))}
                                placeholder={field.title}
                                value={selectedFilters.get(field.field)?.map(value => value).join(', ')}
                            />
                        )
                    })}
                </div>
            </div>
            
    )
};

Filters.propTypes = {
    fields: PropTypes.any,
    onFilter: PropTypes.func,
}

export default Filters;