import React, { Component } from "react"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"


class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      width: 0,
      height: 0
    }
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  render() {

    return (        
      <div className="vertical-collapsed">
        <Header />
        <Sidebar />
        <div className="main-content">{this.props.children}</div>
        <Footer />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default withRouter(Layout);