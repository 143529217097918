import { api } from "services"

export default {
    getAvailableOrders: async () => {
        return api.get('/')
    },
    getAssignedLicences: async () => {
        return api.get('/')
    },
    assignNewLicence: async (payload) => {
        return api.post('/', payload)
    }
}