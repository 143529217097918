import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ className, onSubmit, children }) => {
    return (
        <form className={className} onSubmit={onSubmit}>
            { children }
        </form>
    )
};

Form.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
}

export default Form;