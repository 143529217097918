import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { format, isWithinInterval, startOfDay } from "date-fns";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Spinner, Button, Card, CardBody, Progress } from "reactstrap";
import { Link } from "react-router-dom";

import { getIndividualsData } from "store/actions";
import { downloadCSV, objToCSV } from "helpers/TableDownload";
import Filters from "./filters";
import Checkbox from "components/Common/Checkbox/Checkbox";
import FormDropdown from "components/Common/Dropdown/Dropdown";
import Caret from "components/Common/Caret/Caret";

import DownloadIcon from "../../assets/images/download_icon.svg";
import ColumnsIcon from "../../assets/images/columns_icon.svg";

import "./individualsTable.scss";
import IconTooltip from "components/Common/Tooltip/IconTooltip";
import {
  FilterableFields,
  IndividualsTableAvailableFields,
  filterOnlyAvailableFields,
} from "domains/individuals";

// const STUDY_PACE = Object.freeze({
//     'On Track': 'onTrack',
//     'At Risk': 'atRisk',
//     'Needs Attention': 'needsAttention',
//     'Graduated': 'graduated',
//     'Completed': 'completed',
// });

const IndividualsTable = ({
  individualsData,
  loading,
  getIndividualsDataAction,
}) => {
  const [rawData, setRawData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(new Map());
  const [tableConfig, setTableConfig] = useState(new Map());

  const defaultColumns = [
    {
      text: "",
      dataField: "randomId",
      hidden: true,
    },
    {
      text: "",
      dataField: "position",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => (
        <Link to="#" className="text-body fw-bold">
          #{rowIndex + 1}
        </Link>
      ),
    },
    {
      dataField: IndividualsTableAvailableFields.firstName,
      text: "First name",
      headerClasses: "header__sortable",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.lastName,
      text: "Last name",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerClasses: "header__sortable",
    },
    {
      dataField: IndividualsTableAvailableFields.email,
      text: "Email",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerClasses: "header__sortable",
    },
    {
      dataField: "contract",
      text: "Contract",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerClasses: "header__sortable",
    },
    {
      dataField: IndividualsTableAvailableFields.bookingFrequency,
      text: "Booking frequency",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerClasses: "header__sortable",
    },
    //{
    //    dataField: "licenseduration",
    //    text: "Licence duration",
    //    headerClasses: "header__sortable",
    //    sort: true,
    //    sortCaret: (order, column) => {
    //        return <Caret dataField={column.dataField} key={column.dataField} sortOrder={order}/>
    //    },
    // eslint-disable-next-line react/display-name
    //    formatter: (cell) => {
    //        if (cell > 1) return `${cell} months`
    //        return `${cell} month`
    //    },
    //},
    {
      dataField: IndividualsTableAvailableFields.licenceType,
      text: "Licence type",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerClasses: "header__sortable",
    },
    {
      dataField: IndividualsTableAvailableFields.status,
      text: "Licence status",
      headerClasses: "header__sortable",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              position="bottom"
              text={
                <p>
                  The following types of licence status may be shown:
                  <br />
                  <br />
                  <p>
                    -<strong> Active</strong> = The student has been invited
                  </p>
                  <br />
                  <p>
                    -<strong> Expired</strong> = The licence end date has passed
                  </p>
                </p>
              }
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.courseLanguage,
      text: "Language",
      headerClasses: "header__sortable",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.courseLevel,
      text: "Level",
      headerClasses: "header__sortable",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.startDate,
      text: "Onboarding date",
      headerClasses: "header__sortable",
      formatter: cellContent => {
        if (!cellContent) return null;
        return format(startOfDay(new Date(cellContent)), "do MMM yyyy");
      },
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              text={"Onboarding date"}
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.expiryDate,
      text: "Expiry date",
      headerClasses: "split-right header__sortable",
      classes: "split-right",
      formatter: cellContent => {
        if (!cellContent) return null;
        return format(new Date(cellContent), "do MMM yyyy");
      },
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              text={"The licence expiry date"}
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.introductoryClass,
      text: "Intro class",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              text={"Intro class happened or booked"}
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.currentLesson,
      text: "Current lesson",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              text={"The current lesson the learner is studying"}
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.expectedLesson,
      text: "Target lesson",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              position="bottom"
              text={
                <p>
                  This is the lesson we expect learners to be on if they follow
                  the suggested study pace:
                  <br />
                  <br />
                  <p>- 1x lesson per week for 6 month licences</p>
                  <p>- 1x lesson every 2 weeks for 12 month licences</p>
                  <br />
                  <p>
                    <strong>Note:</strong> During the initial stages of study,
                    the Target Lesson will show as 0 as this will be during the
                    buffer time inbuilt to each license
                  </p>
                </p>
              }
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.engagementStatus,
      text: "Engagement",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              position="bottom"
              text={
                <p>
                  The following types of <strong>Engagement</strong> will be
                  shown:
                  <br />
                  <br />
                  <p>
                    -<strong> Active</strong> = The learner logged in to the
                    platform to study within the last 2 weeks
                  </p>
                  <br />
                  <p>
                    -<strong> Inactive</strong> = The learner did not log in to
                    the platform to study within the last 2 weeks
                  </p>
                  <br />
                  <p>
                    -<strong> Completed</strong> = This will be shown for
                    expired licences
                  </p>
                </p>
              }
            />
          </div>
        );
      },
    },
    // {
    //     dataField: "studyPace",
    //     text: "Study pace",
    //     classes: "cell__without-padding",
    //     headerClasses: "header__sortable",
    //     sort: true,
    //     sortCaret: (order, column) => {
    //         return <Caret dataField={column.dataField} key={column.dataField} sortOrder={order}/>
    //     },
    //     // eslint-disable-next-line react/display-name
    //     formatter: (cellContent, row) => {
    //         return (
    //             <div className={`studyPace ${STUDY_PACE[cellContent]}`}>
    //                 <p>{ cellContent }</p>
    //             </div>
    //         )
    //     },
    //     headerFormatter: (column, colIndex, components) => {
    //         return (
    //             <div className="d-flex justify-content-between">
    //                 <div>
    //                     <span>{column.text}</span>
    //                     {components.sortElement}
    //                 </div>
    //                 <IconTooltip
    //                     icon={<i className="mdi mdi-information"></i>}
    //                     moreThanOneLine
    //                     position='bottom'
    //                     text={<p>The following types of <strong>Study pace</strong> will be shown:
    //                         <br/>
    //                         <br/>
    //                         <p>-<strong> On track</strong> = The learner is on track to complete the full course by their license expiry date. (including Live Practices)</p>
    //                         <br/>
    //                         <p>-<strong> Needs attention</strong> = The student is between 1 to 4 lessons behind this week’s target lesson number. They need to schedule lessons more frequently in order to finish the course by their license expiry date. :</p>
    //                         <br/>
    //                         <p>-<strong> At risk</strong> = The student is behind this week’s target lesson number, by at least 4 lessons. They need to schedule lessons more frequently in order to finish the course by their license expiry date.  </p>
    //                         <br/>
    //                     </p>}
    //                 />
    //             </div>
    //         )
    //     }
    // },
    {
      dataField: IndividualsTableAvailableFields.averageCompletion,
      text: "Avg. completion",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      // eslint-disable-next-line react/display-name
      formatter: cellContent => {
        const value = Number(cellContent * 100).toFixed(0);
        return (
          <div className="progress-bar">
            <div>
              <Progress
                value={value}
                color={stablishProgressionBarCollor(value)}
              />
            </div>
            <p>{value}%</p>
          </div>
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              text={"This is the average completion of each self-study lesson"}
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.livePracticesCompleted,
      text: "Attended live practice",
      formatter: cellContent => (cellContent ? cellContent : 0),
      headerClasses: "split-right header__sortable",
      classes: "split-right",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              text={
                "This is the total number of Live Practices that the learners has attended"
              }
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.timeSelfStudy,
      text: "Self-study",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              position="left"
              text={
                "This is the total amount of time the learner has spent completing the self study learning material"
              }
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.timeLiveClass,
      text: "Live practice",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              position="left"
              text={
                "This is the total amount of time the learner has spent in Live Practice"
              }
            />
          </div>
        );
      },
    },
    {
      dataField: IndividualsTableAvailableFields.totalStudy,
      text: "Total Time",
      sort: true,
      sortCaret: (order, column) => {
        return (
          <Caret
            dataField={column.dataField}
            key={column.dataField}
            sortOrder={order}
          />
        );
      },
      headerFormatter: (column, colIndex, components) => {
        return (
          <div className="d-flex justify-content-between">
            <div>
              <span>{column.text}</span>
              {components.sortElement}
            </div>
            <IconTooltip
              icon={<i className="mdi mdi-information"></i>}
              moreThanOneLine
              position="left"
              text={
                "This is the total combined time that a learner has spent completing the self study material and in Live Practice"
              }
            />
          </div>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    getIndividualsDataAction();
  }, []);

  useEffect(() => {
    if (individualsData.length) {
      setRawData(individualsData);
      setTableData(individualsData);
      filterFactory(individualsData);
      tableConfigFactory();
    }
  }, [individualsData]);

  const downloadTable = () => {
    const onlyAvailableFields = filterOnlyAvailableFields(rawData);
    const csvParsedData = objToCSV(onlyAvailableFields);
    downloadCSV(csvParsedData);
  };

  const stablishProgressionBarCollor = value => {
    if (Number(value) < 25) return "danger";
    if (Number(value) < 50) return "warning";
    if (Number(value) < 75) return "info";
    return "success";
  };

  const filterFactory = data => {
    if (!data) return;
    const filterMap = new Map();
    Object.keys(FilterableFields).forEach(field => {
      filterMap.set(FilterableFields[field], {
        title: FilterableFields[field],
        field,
        data: Array.from(
          new Set(
            data
              .map(row => {
                if (field.includes("Date") && row[field])
                  return format(new Date(row[field]), "do MMM yyyy");
                return row[field];
              })
              .filter(value => value)
          )
        ),
      });
    });
    setFilters(filterMap);
  };

  const filter = (dataSource, filter) => {
    const { tableColumn, value } = filter;
    return dataSource.filter(row => {
      if (!value.length) return true;

      if (tableColumn.includes("Date"))
        return value.reduce((acc, filterValue) => {
          return (
            acc || isWithinInterval(new Date(row[tableColumn]), filterValue)
          );
        }, false);

      return value.reduce((acc, filterValue) => {
        return acc || row[tableColumn] === filterValue;
      }, false);
    });
  };

  const handleFilterSelection = filters => {
    const result = Array.from(filters.keys()).reduce((acc, tableColumn) => {
      const selectedFilter = { tableColumn, value: filters.get(tableColumn) };
      return filter(acc, selectedFilter);
    }, rawData);

    setTableData(result);
  };

  const tableConfigFactory = () => {
    if (!defaultColumns) return;
    const initialMap = new Map();
    defaultColumns.forEach(({ dataField }) => initialMap.set(dataField, true));
    setTableConfig(initialMap);
  };

  const handleTableConfigItemSelection = item => {
    const config = tableConfig;
    config.set(item.dataField, item.checked);

    const activeColumns = defaultColumns.map(column => {
      if (!tableConfig.get(column.dataField))
        return { ...column, hidden: true };
      return column;
    });

    setTableConfig(config);
    setColumns(activeColumns);
  };

  const pageOptions = {
    sizePerPage: 100,
    totalSize: columns.length,
    custom: true,
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: false,
    classes: "selectedRow",
    // eslint-disable-next-line react/display-name
    selectionRenderer: ({ checked }) => {
      return (
        <input
          className="form-check-input checkbox"
          type="checkbox"
          value=""
          readOnly
          checked={checked}
          key={Math.random()}
        />
      );
    },
  };

  const expandRow = {
    // eslint-disable-next-line react/display-name
    renderer: (row, rowIndex) => (
      <div key={row.id}>
        <p>{`This Expand row is belong to rowKey ${row.id}`}</p>
        <p>
          You can render anything here, also you can add additional data on
          every row object
        </p>
        <p>
          expandRow.renderer callback will pass the origin row object to you
        </p>
      </div>
    ),
  };

  return (
    <Card>
      <CardBody>
        {loading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner type="grow" />{" "}
          </div>
        ) : (
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="courseprogressId"
            columns={columns}
            data={tableData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="courseprogressId"
                data={tableData}
                columns={columns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <>
                    <div className="table-responsive individuals-table">
                      <div className="individuals-table__table-headers ">
                        <div className="title">
                          <h4>Students</h4>
                        </div>
                        <div className="filters-wrapper">
                          <Filters
                            fields={filters}
                            onFilter={handleFilterSelection}
                            {...toolkitProps.searchProps}
                          />
                          <Button
                            onClick={downloadTable}
                            id="individuals-button-download-table"
                            className="action-button"
                          >
                            <img src={DownloadIcon} alt="Download table" />
                          </Button>
                          <FormDropdown
                            buttonClasses={["action-button"]}
                            id="individuals-button-customize-table-headers"
                            options={columns.map(
                              column =>
                                column.text && (
                                  <Checkbox
                                    checked={tableConfig.get(column.dataField)}
                                    dataField={column.dataField}
                                    key={column.dataField}
                                    id={`individuals-button-customize-table-headers-${column.dataField}`}
                                    onCheck={handleTableConfigItemSelection}
                                    text={column.text}
                                  />
                                )
                            )}
                            placeholder={
                              <img
                                src={ColumnsIcon}
                                alt="Change visible headers"
                              />
                            }
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        keyField={"randomId"}
                        loading={loading}
                        responsive
                        bordered={false}
                        classes="table align-middle table-nowrap table-check"
                        headerWrapperClasses="table-light"
                        rowClasses="individuals-row"
                      />
                    </div>
                    <div className="pagination pagination-rounded justify-content-end">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}
      </CardBody>
    </Card>
  );
};

IndividualsTable.propTypes = {
  checked: PropTypes.bool,
  individualsData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getIndividualsDataAction: PropTypes.func,
};

const mapStateToProps = state => ({
  individualsData: state.Individuals.individualsData,
  loading: state.Individuals.loading,
});

export default connect(mapStateToProps, {
  getIndividualsDataAction: getIndividualsData,
})(IndividualsTable);
